import Model from '@/shared/classes/model'
import IInstructionTemplate from '@/shared/modules/instruction-templates/instruction-template-response.interface'
import InstructionCategory from '@/shared/modules/instruction-categories/instruction-category.model'

export default class InstructionTemplate extends Model<IInstructionTemplate> {
  id!: number
  name!: string
  content!: string
  instructionCategoryId!: number
  instructionCategoryName!: string
  instructionCategory!: InstructionCategory
  isVisible!: boolean

  transform(data: IInstructionTemplate): void {
    this.id = data.id
    this.name = data.name
    this.content = data.content
    this.instructionCategoryId = data.instruction_category_id
    this.isVisible = data.is_visible
    this.instructionCategory = this.setRelation(InstructionCategory, data.instruction_category)
    this.instructionCategoryName = this.instructionCategory.name
  }
}
