import Service from '@/shared/classes/service'

export default class InstructionTemplateService extends Service {
  static prefix = 'instruction-templates'

  static destroy(id: number): Promise<any> {
    return this.delete(`/${ this.prefix }/${ id }`)
  }

  static show(id: number): Promise<any> {
    return this.get(`/${ this.prefix }/${ id }`)
  }
}
