















import { Component, Prop, Vue } from 'vue-property-decorator'
import __ from '@/shared/helpers/__'
import PageHeader from '@/admin/components/PageHeader.vue'
import { AdminRoutes } from '@/shared/router/admin'
import FormBase from '@/shared/classes/form/form-base'
import Field, { FieldSizes } from '@/shared/classes/form/field'
import { FieldTypes } from '@/shared/components/form/field-types'
import { GlobalActions } from '@/shared/store/global/global.actions'
import { SnackBarTypes } from '@/shared/helpers/snack-bar.helper'
import Form from '@/shared/components/form/Form.vue'
import SearchableField from '@/shared/classes/form/fields/Select/searchable-field'
import SelectField from '@/shared/classes/form/fields/Select/select-field'
import SelectItem from '@/shared/classes/form/fields/Select/select-item'
import EditorField from '@/shared/classes/form/fields/editor-field'

@Component({
  components: { PageHeader, Form },
  methods: { __ },
})
export default class InstructionTemplatesCreateDialog extends Vue {
  @Prop() meta!: any
  form: FormBase | null = null

  created() {
    this.form = new FormBase()
      .setEndpoint('/instruction-templates')
      .setTranslatable(true)
      .setFields([
        new SearchableField()
          .setKey('instruction_category_id')
          .setEntryKey('instructionCategoryId')
          .setDense(true)
          .isRequired()
          .setTitle(__('admin.components.instruction-templates.create-dialog.form.category'))
          .loadItems({ endpoint: '/instruction-categories', value: 'id', title: 'name', perPage: 20 })
          .setSize(FieldSizes.half),
        new SelectField()
          .setKey('is_visible')
          .setDense(true)
          .setItems([
            new SelectItem()
              .setValue(true)
              .setTitle(__('admin.components.instruction-templates.create-dialog.form.visible-option')),
            new SelectItem()
              .setValue(false)
              .setTitle(__('admin.components.instruction-templates.create-dialog.form.invisible-option')),
          ])
          .setSize(FieldSizes.half)
          .setTitle(__('admin.components.instruction-templates.create-dialog.form.is-visible')),
        new Field()
          .setKey('name')
          .isRequired()
          .setDense(true)
          .setTranslatable(true)
          .setSize(FieldSizes.half)
          .setTitle(__('admin.components.instruction-templates.create-dialog.form.name')),
        new EditorField()
          .setKey('content')
          .setTranslatable(true)
          .setTitle(__('admin.components.instruction-templates.create-dialog.form.content')),
      ])
      .setSubmit({
        text: __('admin.components.instruction-templates.create-dialog.form.submit'),
      })
      .setInitialValues({
        is_visible: true,
      })
      .setValidate(true, { onlyRegionLangRequired: true })
      .setOnSuccess(this.meta.onSuccess)
  }

  async closeDialog() {
    await this.$store.dispatch(GlobalActions.closeDialog)
  }
}
