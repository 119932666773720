









import { Component, Vue } from 'vue-property-decorator'
import __ from '@/shared/helpers/__'
import PageHeader from '@/admin/components/PageHeader.vue'
import DataTable from '@/shared/components/data-table/DataTable.vue'
import DataTableBase from '@/shared/classes/components/data-table/data-table'
import DataTableHeader from '@/shared/classes/components/data-table/data-table-header'
import InstructionTemplate from '@/shared/modules/instruction-templates/instruction-template.model'
import DeleteAction from '@/shared/classes/components/data-table/default-actions/delete-action'
import EditAction from '@/shared/classes/components/data-table/default-actions/edit-action'
import { GlobalActions } from '@/shared/store/global/global.actions'
import { SnackBarTypes } from '@/shared/helpers/snack-bar.helper'
import InstructionTemplatesCreateDialog
  from '@/admin/components/views/instruction-templates/InstructionTemplatesCreateDialog.vue'
import InstructionTemplatesEditDialog
  from '@/admin/components/views/instruction-templates/InstructionTemplatesEditDialog.vue'
import AreYouSureDialog from '@/shared/components/dialogs/AreYouSureDialog.vue'
import InstructionTemplateService from '@/shared/modules/instruction-templates/instruction-template.service'

@Component({
  components: { DataTable, PageHeader },
  methods: { __ },
})
export default class CompaniesIndex extends Vue {
  table: DataTableBase | null = null

  created() {
    this.table = new DataTableBase()
      .setModel(InstructionTemplate)
      .setEndpoint('instruction-templates')
      .setGroupBy('instructionCategoryName')
      .setGroupDesc(false)
      .setDoubleClick(this.openEditDialog)
      .setHeaders([
        new DataTableHeader()
          .setKey('name')
          .setSortable(false)
          .setText(__('admin.views.instruction-templates.index.table.columns.name')),
        new DataTableHeader()
          .setKey('is_visible')
          .setSortable(false)
          .setValueParser((value: any, item: InstructionTemplate) => {
            if (item.isVisible) {
              return __('admin.views.instruction-templates.index.table.columns.visible-option')
            }

            return __('admin.views.instruction-templates.index.table.columns.invisible-option')
          })
          .setText(__('admin.views.instruction-templates.index.table.columns.is-visible')),
      ])
      .setActions([
        new EditAction(false).setAction(this.openEditDialog),
        new DeleteAction().setAction(this.deleteTemplate),
      ])
  }

  openCreateDialog(): void {
    this.$store.dispatch(GlobalActions.showDialog, {
      show: true,
      component: InstructionTemplatesCreateDialog,
      maxWidth: 1200,
      persistent: true,
      meta: {
        onSuccess: () => {
          this.tableRef.refresh()
          this.$store.dispatch(GlobalActions.closeDialog)
          this.$store.dispatch(GlobalActions.showSnackBar, {
            type: SnackBarTypes.success,
            message: __('admin.components.instruction-templates.create-dialog.on-success'),
          })
        },
      },
    })
  }

  openEditDialog(instructionTemplate: InstructionTemplate): void {
    this.$store.dispatch(GlobalActions.showDialog, {
      show: true,
      component: InstructionTemplatesEditDialog,
      maxWidth: 1200,
      persistent: true,
      meta: {
        instructionTemplate,
        onSuccess: () => {
          this.tableRef.refresh()
          this.$store.dispatch(GlobalActions.closeDialog)
          this.$store.dispatch(GlobalActions.showSnackBar, {
            type: SnackBarTypes.success,
            message: __('admin.components.instruction-templates.edit-dialog.on-success'),
          })
        },
      },
    })
  }

  deleteTemplate(instructionTemplate: InstructionTemplate): void {
    this.$store.dispatch(GlobalActions.showDialog, {
      show: true,
      component: AreYouSureDialog,
      meta: {
        title: __('admin.views.instruction-templates.index.table.action.delete.title'),
        text: __('admin.views.instruction-templates.index.table.action.delete.text', { name: instructionTemplate.name }),
        onYes: () => InstructionTemplateService.destroy(instructionTemplate.id)
          .then(() => {
            this.tableRef.refresh()
            this.$store.dispatch(GlobalActions.showSnackBar, {
              type: SnackBarTypes.success,
              message: __('admin.views.instruction-templates.index.table.action.delete.on-success'),
            })
          }),
      },
    })
  }

  get tableRef(): any {
    return this.$refs.table
  }
}
